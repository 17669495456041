@import 'assets/stylesheets/colors.scss';

.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  
  .divider {
    border: solid 1px $datepicker-border-grey;
    width: 15px;
    margin: 0px 5px;
  }

  .input {
    height: 41px;
    border-radius: 5px;
    border: solid 1px $datepicker-border-grey;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.44px;
    color: $twilight-blue;
  }
  
  select {
    @extend .input;
    background: url(../../assets/images/chevronDownOrange.svg) no-repeat scroll 170px 14px;
    background-color: white;
    min-width: 210px;
    padding-left: 15px;
  }
  
  input {
    @extend .input;
    background: url(../../assets/images/icons-calendar.svg) no-repeat scroll 140px 7px;
    background-color: white;
    width: 180px;
    min-width: 180px;
    text-align: center;
  }

  input::-webkit-inner-spin-button,
  input::-webkit-calendar-picker-indicator {
      opacity: 0;
      margin: 0px 13px 0px 0px
  }
}
