@import '../../../../assets/stylesheets/colors.scss';

.card {
  height: 36px;
  margin-bottom: 14px;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  border: none;
  background: none;
  width: 100%;
}

.selectedCard {
  @extend .card;
  background-color: $very-light-pink;
}

.main {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  color: $twilight-blue;
}

.checkbox {
  width: 20px;
  height: 20px;
  visibility: hidden;
}

.children {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
