@import 'assets/stylesheets/colors.scss';

.container {
  width: 304px;

  .bar {
    height: 24px;
    border-radius: 12px;
    background-color: $occupancy-threshold-background;
    overflow: hidden;
    .barProgress { height: 100%; }
  }

  .percentage {
    text-align: left;
    text-indent: 14px;
    font-size: 14px;
    margin: 9px 0px 0px 14px;
  }

  .numbers {
    height: 71px;
    margin: 35px 0px 10px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    overflow: hidden;
    .box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      height: 100%;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 0.28px;
      div {
        text-align: center;
      }
      span {
        font-size: 29px;
        font-weight: 600;
        letter-spacing: 0.81px;
      }
    }

    .occupancy {
      @extend .box;
      color: white;
    }

    .threshold {
      @extend .box;
      color: $twilight-blue;
      background-color: $occupancy-threshold-background;
    }
  }
}

.container > * { width: 100%; }

.liveContainer {
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .occupancy {
    font-weight: 300;
    font-size: 30px;
  }

  .infringements {
    font-weight: 300;
    font-size: 24px;
  }

  .lastUpdated {
    font-weight: 300;
    margin: 10px;
  }
}
