.container {
    display: flex;
    justify-content: center;
    align-items: center;

  canvas {
    background-color: transparent;
    position: absolute;
  }
}
