@import 'assets/stylesheets/colors.scss';

.heatmapContainer {
  width: 540px;
  height: 405px;
  margin-bottom: 70px;
  padding-left: 30px;
}

.title {
  font-size: 36px;
  color: $dark-indigo;
  text-align: center;
  margin-bottom: 45px;
  margin-top: 30px;
  font-weight: normal;
}

.dateContainer {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-left: 40px;
  padding-right: 40px;

  .dateText {
    font-size: 17px;
    color: $dark-indigo;
    text-align: center;
    margin-right: 20px;
  }

  .dateButton {
    font-size: 17px;
    height: 50px;
    color: white;
    width: 233px;
    border-radius: 8px;
    background-color: #8549e2;
  }
}

.modal {
  width: 600px;
  background: white;
  border: 1px solid #ccc;
  box-shadow: -2rem 2rem 2rem rgba(black, 0.2);
  filter: blur(0);
  transform: scale(1);
  opacity: 1;
  border-radius: 5px;

  h2 {
    border-bottom: 1px solid #ccc;
    padding: 1rem;
    margin: 0;
    color: $dark-indigo;
  }

  .content {
    margin-top: 20px;
    padding: 1rem;
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;

    .dateInput {
      height: 40px;
      width: 200px;
      cursor: pointer;
      border-radius: 5px;
      padding: 5px;
      font-size: 16px;
    }

    .dateLabel {
      margin-top: 15px;
      color: $dark-indigo;
      font-size: 18px;
    }
  }

  p {
    border: 0;
    color: $error-text-red;
    font-size: 0.7rem;
    line-height: 1;
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
  }

  .actions {
    padding: 0.5rem 1rem;
    float: right;
  }
  .applyButton {
    width: 100px;
  }
}
