@import 'assets/stylesheets/colors.scss';
@import 'assets/stylesheets/mixins.scss';

.modal {
  position: fixed;
  min-height: 100%;
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.background {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.9;
  z-index: -1;
}

.dismissButton {
  width: 100%;
  height: 100%;
  opacity: 0;
}
