@import 'assets/stylesheets/colors.scss';
@import 'assets/stylesheets/mixins.scss';

.button {
  width: 217px;
  height: 70px;
  border-style: none;
  border-radius: 8px;
  font-size: 22.5px;
  letter-spacing: -0.79px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  @include up-to-xs {
    font-size: 18px;
    height: 58px;
  }
}

.button[short=true] { height: 48px; }
.button[wide=true] { width: 100%; }

// Colors
.button[color=grey] { color: $dark-indigo; background-color: $button-grey; }
.button[color=sky] { color: white; background-color: $button-skyblue; }
.button[color=white] { color: $twilight-blue; background-color: white; border: solid 2px $pale-grey-two; }
.button[color=twilightBlue] { background-color: $twilight-blue; color: white; }
.button[color=lanthornRed] { background-color: $delete-button-red; color: white; }

.button:disabled { color: white; background-color: $disabled-button-grey; }

.loader {
  padding: 0;
  margin: 0;
  height: 0;
  width: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@keyframes blink {
  25% { opacity: 0 }
  50% { opacity: 0 }
  75% { opacity: 1 }
}

.dot {
  height: 100%;
  padding: 0;
  margin: 0;
  width: calc(5 / 17 * 100%);
  border-radius: 50%;
  background-color: transparent;
  animation: blink 2s infinite;
}

.dot:nth-child(2) { animation-delay: 0.25s; }
.dot:nth-child(3) { animation-delay: 0.5s; }
