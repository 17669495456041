@import 'assets/stylesheets/colors.scss';
@import 'assets/stylesheets/fonts.scss';
@import 'assets/stylesheets/mixins.scss';

.cardTitle {
  @extend .title;
  margin-top: 51px;
  @include up-to-xs {
    font-size: 31px;
    margin-top: 20px;
  }
}

.subTitle {
  @extend .secondaryText;
  margin-top: 81px;
  @include up-to-xs {
    font-size: 18px;
    margin-top: 60px;
  }
}

.button {
  margin: 100px 0px 88px 0px;
  @include up-to-xs {
    margin: 54px 0px 36px 0px;
  }
}
