@import 'assets/stylesheets/colors.scss';
@import 'assets/stylesheets/mixins.scss';
@import '../common.module.scss';

.formContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.camerasContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 15px;
}

.addCameraButtonDiv {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 17px;
  @include up-to-xs {
    justify-content: flex-start;
  }
}

.cameraListContainer {
  width: 100%;
  min-height: 90px;
  bottom: 112px;
  border-radius: 8px;
  border: solid 1px $light-periwinkle;
  border-style: dashed;
  margin-top: 28px;
}

.cameraList {
  @extend .subtitle;
  margin-top: 17px;
  margin-left: 24px;
}

.cameraListButtonContainer {
  margin: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.loader {
  margin: 40px 50px;
  transform: scale(0.5);
}

.calibrateButton {
  padding: 3px 8px 3px 12px;
  height: 30px;
  border-radius: 4px;
  background-color: $bluey-grey;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.8px;
  color: white;
  border-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;
  cursor: pointer;
  img {
    margin-left: 5px;
  }
}

.selectAreaButton {
  @extend .calibrateButton;
  padding: 3px 32px 3px 12px;
  img {
    max-height: 90%;
    margin-left: 8px;
  }
}

.inOutButton {
  @extend .calibrateButton;
  padding: 3px 10px 3px 12px;
  img {
    max-height: 90%;
    margin-left: 8px;
  }
}
