@import 'assets/stylesheets/colors.scss';
@import 'assets/stylesheets/variables.scss';
/*
  When using design systems it is a good practice to match
  the text styles of it on the project. Add all text styles here
  as classes. Design systems sometimes vary on implementation, so
  implement the text styles as you see fit.

  For instance, this could be an example of a text style class:

  .headerActive {
    font-family: ...
    font-style: ...
    color: ...
  }

  Use mixins if you want to add color to all text to avoid repetition.
*/
.workSansRegular {
  font-family: $primary-font;
  color: $dark-indigo;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
}
.workSansRegularLight {
  font-family: $primary-font;
  color: $dark-indigo;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  letter-spacing: 1.35px;
}
.WorkSansRegularMedium {
  font-family: $primary-font;
  color: $dark-indigo;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.47px;
}

.selectText {
  font-family: $primary-font;
  font-size: 19px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.53px;
}

.workSansRegularSemiBold {
  font-family: $primary-font;
  font-size: 21px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.58px;
  color: $dark-indigo;
}

.workSansRegularMediumDashboard {
  font-family: $primary-font;
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.64px;
  color: $dark-indigo;
}
