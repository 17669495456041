@import 'assets/stylesheets/colors.scss';

.calloutDiv {
  width: 100%;
  border-left: 6px solid;
}

.text {
  font-size: 18px;
  font-weight: 500;
  padding: 18px 12px 17px 17px
}

.calloutDiv[type=warning] {
  background-color: $very-light-pink-two;
  border-color: $rusty-orange;
}

.text[type=warning] {
  color: $green-brown;
}
