@import 'src/assets/stylesheets/colors';
@import 'src/assets/stylesheets/mixins.scss';

.formContainer {
  width: 100%;
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 36px 0px 36px 0px;
  box-sizing: border-box;

  input {
    margin-bottom: 12px;
  }

  select {
    margin-bottom: 12px;
  }
}

.backgroundEditing {
  background-color: inherit;
}

::placeholder {
  color: #949494;
  font-size: 22px;
}

.namesContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.registerText {
  font-size: 44px;
  color: $halloween-black;
  margin-bottom: 40px;
  text-align: center;
  font-weight: 300;
  @include up-to-xs {
    font-size: 31px;
  }
}

.termsAndConditionsCheckbox {
  font-size: 17px;
  color: $halloween-black;
  margin: 10px 0px 20px 0px;
  display: flex;
  flex-direction: row;
  @include up-to-xs {
    font-size: 14px;
  }

  .termsLink {
    width: auto;
    font-size: 17px;
    padding: 0;
    text-decoration: underline;
    background:none;
    border:none;
    margin: 0px 0px 0px 6px;
    padding:0;
    cursor: pointer;
    @include up-to-xs {
      font-size: 14px;
    }
  }
}

.modalContainer {
  position: relative;
  max-height: 700px;
  width: 50%;
  padding: 40px;
  background-color: white;
  color: $dark-indigo;
  font-size: 17px;
  overflow-y: auto;
  @include up-to-xs {
    height: 90%;
    width: 80%;
    padding: 20px;
    font-size: 14px;
  }
  @include between-xs-sm {
    width: 70%;
  }
  .header {
    font-size: 28px;
    margin-bottom: 35px;
    @include up-to-xs {
      margin-bottom: 25px;
    }
  }
}

.backgroundLoading {
  background: $background-loading-dark;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  position: absolute;
  top: 0;
}

.loadingDiv{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
}

.errorsText {
  color: $error-text-red;
  line-height: 1.6;
  text-align: center;
  margin-bottom: 15px;
}

.scoreContainer {
  margin-bottom: 12px;
  text-align: end;
  font-size: 19px;
  @include up-to-xs {
    font-size: 16px;
  }
}
.closeIcon {
  position: absolute;
  top: 26px;
  right: 8%;
  background-color: transparent;
  border: none;
  @include beyond-xl {
    visibility: hidden;
  }
}
details {
  margin-top: 8px;
  margin-bottom: 12px;
}
summary {
  margin-bottom: 8px;
}
