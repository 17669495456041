@import 'assets/stylesheets/mixins.scss';
@import 'assets/stylesheets/text-styles.scss';

.genericContainer {
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
}
$generic-item-margin: 20px;
/*
  It is assumed this container will be used directly inside
  a generic-container
*/
.genericItemContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.genericItemContainer > * {
  margin-right: $generic-item-margin;
  margin-bottom: $generic-item-margin;
}

@include beyond-xl {
  .genericContainer {
    max-width: map-get($breakpoints, "xl");
  }
  .genericItem-container > * {
    /*
      The last item on the row has no margin, so we need to redistribute that space
      equally on all items.
    */
    width: calc((100% / 4) - (var($generic-item-margin) - var($generic-item-margin) / 4));
  }
  .genericItemContainer > *:nth-child(4n) {
    margin-right: 0;
  }
}

@include between-lg-xl {
  .genericContainer {
    max-width: map-get($breakpoints, "ls");
  }
  .genericItemContainer > * {
    /*
      The last item on the row has no margin, so we need to redistribute that space
      equally on all items.
    */
    width: calc((100% / 3) - (var($generic-item-margin) - var($generic-item-margin) / 3));
  }
  .genericItemContainer > *:nth-child(3n) {
    margin-right: 0;
  }
}
@include between-md-lg {
  .genericContainer {
    max-width: map-get($breakpoints, "md");
  }
  .genericItemContainer > * {
    /*
      The last item on the row has no margin, so we need to redistribute that space
      equally on all items.
    */
    width: calc((100% / 3) - (var($generic-item-margin) - var($generic-item-margin) / 3));
  }
  .genericItemContainer > *:nth-child(3n) {
    margin-right: 0;
  }
}
@include between-sm-md {
  .genericContainer {
    max-width: map-get($breakpoints, "sm");
  }
  .genericItemContainer > * {
    /*
      The last item on the row has no margin, so we need to redistribute that space
      equally on all items.
    */
    width: calc((100% / 2) - (var($generic-item-margin) - var($generic-item-margin) / 2));
  }
  .genericItemContainer > *:nth-child(2n) {
    margin-right: 0;
  }
}
@include between-xs-sm {
  .genericContainer {
    max-width: map-get($breakpoints, "xs");
  }
  .genericItemContainer > * {
    /*
      The last item on the row has no margin, so we need to redistribute that space
      equally on all items.
    */
    width: calc((100% / 2) - (var($generic-item-margin) - var($generic-item-margin) / 2));
  }
  .genericItemContainer > *:nth-child(2n) {
    margin-right: 0;
  }
}
@include up-to-xs {
  .genericContainer {
    margin: 0;
    padding-left: 15px;
    padding-right: 15px;
  }
  .genericItemContainer {
    margin-right: 0;
    margin-left: 0;
  }
  .genericItemContainer > * {
    width: 100%;
    margin-right: 0;
  }
}
