@import 'assets/stylesheets/colors.scss';

.modalForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.passwordField {
  margin-top: 30px;
}

.urlField {
  margin-top: 50px;
}

.passwordURLField {
  margin-top: 10px;
}

.errorMessage {
  margin-top: 10px;
  font-size: 20px;
  font-weight: 200;
  color: $error-text-red;
}
