@import 'assets/stylesheets/colors.scss';
@import 'assets/stylesheets/mixins.scss';

.button {
  padding: 3px 14.4px 3px 14.4px;
  height: 30px;
  border-radius: 4px;
  background-color: $bluey-grey;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.8px;
  color: white;
  border-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.icon {
  margin-left: 8px;
}
