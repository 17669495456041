@import 'assets/stylesheets/colors.scss';

.outerDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 712px;
}

.title {
  margin-left: 21px;
  font-size: 34px;
}

.cameraDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cameraTitle {
  font-size: 18px;
  margin-bottom: 9px;
}

.videoDiv {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.videoStreaming {
  width: 712px;
  min-height: 100%;
  position: relative;
}

.videoButtonCalibrateDiv {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.calibrateCameraButton {
  font-size: 17px;
  letter-spacing: 0.47px;
  color: white;
  border-radius: 0px 0px 8px 8px;
  background-color: $tab-button-selected;
  border-style: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 225px;

  img {
    margin-right: 8px;
  }

}

.processorRebootDiv {
  top: 0px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: $processor-reboot-grey;
}

.processorRebootText {
  padding: 20px
}

.videoBigPlayButton {
  visibility: hidden;
}

.loader {
  transform: scale(0.75);
  margin: 50px;
}
