@import 'assets/stylesheets/colors.scss';
@import 'assets/stylesheets/fonts.scss';
@import 'assets/stylesheets/mixins.scss';

.title {
  margin-top: 51px;
  @include up-to-xs {
    font-size: 31px;
    margin-top: 20px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  button[type="submit"] {
    margin-top: 32px;
  }
}

.errorMessage {
  margin-top: 8px;
  font-size: 20px;
  font-weight: 200;
  text-align: center;
  color: $error-text-red;
}

.forgotPassword {
  margin-top: 32px;
  height: 24px;
  width: auto;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.67px;
  color: $halloween-black;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  @include up-to-xs {
    font-size: 14px;
  }
}

.forgotPassword ~ .forgotPassword {
  margin-bottom: 32px;
  margin-top: 18px;
}

.createAccount {
  margin-top: 10px;
}

.emailInput {
  margin-top: 68px;
  @include up-to-xs {
    margin-top: 30px;
  }
}
