@import 'assets/stylesheets/colors.scss';

.card {
  height: 329px;
  width: 300px;
  flex-grow: 26;
  border-radius: 5px;
  box-shadow: 0 20px 47px -27px rgba(50, 52, 54, 0.3);
  background-color: white;
  margin: 15px 0px 30px 0px;
  box-sizing: border-box;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 6px;
    padding: 0px 25px 0px 25px;
    td {
      border-radius: 5px;
      background-color: $white-four;
      font-size: 14px;
      color: $twilight-blue;
      height: 30px;
      padding-left: 13px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.title {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.67px;
  color: $dark-indigo;
  margin-bottom: 18px;
  margin-top: 21px;
}

.text {
  font-size: 16px;
  letter-spacing: 0.44px;
  color: $dark-indigo;
}
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $bluegrey;
  border: none;
  width: 123px;
  height: 100%;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.8px;
  color: white;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  img {
    margin-left: 5px;
  }
}
.footer {
  display: flex;
  justify-content: space-around;
  width: 100%;
  position: relative;
}
.arrowButton {
  font-size: 14px;
  font-weight: 600;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: $twilight-blue;
  margin-top: 8px;
}
.nextButton {
  @extend.arrowButton;
  position: absolute;
  right: 25px;
}
.prevButton {
  @extend.arrowButton;
  position: absolute;
  left: 25px;
}
