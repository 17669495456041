@import 'assets/stylesheets/colors.scss';
@import 'assets/stylesheets/mixins.scss';

.container {
  width: 100%;
}

.textInput {
  width: 100%;
  height: 50px;
  border: transparent 1px;
  border-radius: 5px;
  background-color: white;
  text-indent: 16px;
  font-size: 16px;
  color: $twilight-blue;
  box-sizing: border-box;
  @include up-to-xs {
    font-size: 14px;
    height: 48px;
    text-indent: 17px;
  }
}

.textInput[fat=true] {
  height: 68px;
  font-size: 22.1px;
  letter-spacing: 0.61px;
  text-indent: 27px;
  border: solid 1.3px $light-grey;
  border-radius: 15.6px;
  @include up-to-xs {
    font-size: 16px;
    height: 58px;
    text-indent: 17px;
  }
}

.textInput::-webkit-outer-spin-button,
.textInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.textInput:focus {
  outline: none;
  border: solid 1px $lanthorn-orange-light;
}

.textInput::placeholder {
  font-size: 1em;
  color: $bluey-grey;
  opacity: 1 !important;
}

.textInput[disabled] {
  background-color: $button-grey;
  border: solid 1px $disabled-button-grey;
  color: $lanthorn-grey;
}

.textInputError {
  @extend .textInput;
  outline: none;
  border: solid 1px $error-text-red;
  color: $error-text-red;
}

.errorMessage {
  margin: 6px 0px 0px 17px;
  font-size: 14px;
  color: $error-text-red;
}
