@import 'assets/stylesheets/colors.scss';

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  font-weight: 300;
  margin-top: 30px;
  font-size: 19px;
  color: $halloween-black;
  height: auto;
  padding-bottom: 50px;
}

.title {
  font-size: 24px;
  margin-bottom: 30px;
}

.link {
  margin: 0;
  width: auto;
  height: auto;
  font-size: 19px;
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
}

.error {
  margin-top: 15px;
  font-size: 20px;
  font-weight: 200;
  color: $error-text-red;
  text-align: center;
}

.success {
  margin-top: 15px;
  font-size: 20px;
  font-weight: 200;
  color: $success-text-green;
  text-align: center;
}

.loading {
  margin-top: 25px;
}
