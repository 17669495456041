@import 'assets/stylesheets/colors.scss';

.header {
  background-color: transparent;
  border: none;
  width: 100%;
  margin-bottom: 20px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  position: relative;
}

.content {
  width: 100%;
}
.title {
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-left: 8px;
  padding-top: 12px;
  padding-bottom: 12px
}

.subAccordion {
  font-size: 14px;
  margin-left: 14px;
  font-weight: normal;
  padding-top: 11px;
  padding-bottom: 11px;
}

.selectedTitle {
  @extend .title;
  color: $rusty-orange;
}

.unSelectedTitle {
  @extend .title;
  color: $bluey-grey;
}

.selectedHeader {
  @extend .header;
  background-color: $very-light-pink;
}

.unSelectedHeader {
  @extend .header;
  background-color: transparent;
}

.icon {
  margin-right: 5px;
}
.chevron {
  position: absolute;
  right: 16px;
  transition: transform 0.4s ease;
}
.rotate {
  transform: rotate(180deg);
}
