@import 'assets/stylesheets/colors.scss';

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 30px 40px 15px 40px;
  overflow: hidden;
  background-color: $pale-grey;
}

.header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  min-width: 800px;
  width: 100%;
  max-width: 100%;
  margin: 0px 0px 15px 0px;
  
  .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 46px;
    letter-spacing: 1.28px;
    color: $dark-indigo;
    font-weight: normal;
  }
  
  .indicators {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 20px 0px;
    div {
      height: 36px;
      border-radius: 25px;
      border: solid 2px $indicator-border-grey;
      margin: 0px 0px 0px 15px;
      padding: 7px 25px;
      box-sizing: border-box;
      background-color: white;
      color: $indicator-text-grey;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      text-transform: uppercase;
    }
    :first-child {
      margin: 0px;
    }
    div[important] {
      color: $indicator-text-red;
      border: solid 2px $indicator-border-red;
    }
    div[positive] {
      color: $indicator-text-green;
      border: solid 2.5px $indicator-border-green;
    }
  }
}

.timePicker {
  margin: 0px 0px 15px 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-width: 800px;
  width: 100%;
}

.timeSegmentedControlContainer {
  width: 600px;
  margin-right: 30px;
}


.editButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  min-width: 54px;
  height: 54px;
  background-color: white;
  border-radius: 50%;
  border: none;
  margin: 0px 14px;
  cursor: pointer;
}

.areaCardsContainer {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
