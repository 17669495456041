@import 'assets/stylesheets/colors.scss';

.title {
  font-weight: 400;
  font-size: 27px;
  letter-spacing: -0.95px;
  margin: 0px 0px 25px 0px;
  color: $dark-indigo;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 600px;
  max-width: 100%;
  
  h3 {
    font-size: 17px;
    font-weight: normal;
    letter-spacing: -0.6px;
    margin: 10px 0px 20px 0px;
    color: $dark-indigo;
  }
  
  h4 {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.44px;
    color: $twilight-blue;
    margin: 0px 0px 0px 10px;
    color: $dark-indigo;
  }
  
  .h4 {
    margin: 0px 0px 16px 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}

.dateRangePicker {
  margin: 0px 0px 20px 0px;
}

.list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 100%;
  flex-wrap: wrap;
  margin: 0px 0px 20px 0px;
}

.list > * {
  margin: 0px 16px 6px 0px;
}

.areaList {
  @extend .list;
}

.cameraList {
  @extend .list;
}

.metricList {
  @extend .list;
}

.label {
  font-size: 14px;
  color: $twilight-blue;
}
