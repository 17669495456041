@import 'assets/stylesheets/colors.scss';

.background {
  background-color: $main-background-grey;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  width: 594px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 68px;
}

.sectionTitle {
  font-size: 34px;
  font-weight: 300;
  color: $dark-indigo;
  width: 100%;
  text-indent: 20px;
}

.slackContainer {
  width: 594px;
  text-align: center;
  padding-top: 32px;
}

.channelName {
  font-size: 16px;
  font-weight: 600;
  color: $dark-indigo;
  margin: 36px 0 10px 21px;
  text-align: left;
}

.hint {
  margin-top: 26px;
  color: $halloween-black;
  font-size: 16px;
  font-weight: 500;
}

.button {
  margin-top: 67px;
  height: 48px;
  margin-left: 20px;
}

.finish {
  margin-top: 70px;
  font-size: 22px;
  color: $dark-indigo;
  text-align: center;
}

.error {
  margin-top: 20px;
  color: $error-text-red;
}

.link {
  margin: 0;
  width: auto;
  height: auto;
  font-size: 16px;
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
}
