@import 'assets/stylesheets/colors.scss';
@import 'assets/stylesheets/mixins.scss';
@import 'assets/stylesheets/variables.scss';

.navBar {
  background-color: white;
  width: 100%;
  height: $nav-bar-height;
  display: flex;
  justify-content: left;
  position:relative;
  z-index: 0;
}

.demoWarning {
  box-shadow: 0px 6px 20px 5px rgba(107, 108, 116, 0.1);
  width: 100%;
  height: 20px;
  background-color: $twilight-blue;
  color: white;
  text-align: center;
}

.rightItems {
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.section {
  height: 100%;
}

.logoSection {
  @extend .section;
  display: flex;
  align-items: center;
  background: none;
  border: none;
  margin-right: 30px;
  padding: 20px 20px 20px 20px;
  box-sizing: border-box;
  @include up-to-md { margin-right: 0px; }
}

.logoSection[grow=true] {
  flex-grow: 1;
}

.logoImage {
  height: 100%;
  @include up-to-md { height: 0%; }
}

.smallLogoImage {
  height: 0%;
  @include up-to-md { height: 100%; }
}

.buttonSection {
  @extend .section;
  flex-grow: 1;
  display: flex;
  justify-content: left;
}

.errorMessage {
  font-size: 20px;
  font-weight: 200;
  color: $error-text-red;
  @include up-to-sm {
    display: none;
  }
}

.selectDiv {
  display: flex;
  align-items: center;
}

.select {
  background: url(../../assets/images/chevronDown.svg) no-repeat scroll 211px 21px;
  border: none;
  width: 252px;
  height: 60px;
  border-radius: 13.8px;
  background-color: white;
  padding-left: 29px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
}

.dashboardButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  border-radius: 0px;
  height: 100%;
  width: 185px;
  cursor: pointer;

  color: $lanthorn-grey;
  font-size: 16px;

  @include up-to-sm {
    font-size: 0px;
    width: 87px;
    flex-direction: column;
  }

  img {
    margin-right: 12px;
    object-fit: contain;
    max-width: 30px;
    max-height: 30px;
    @include up-to-sm {
      margin-right: 0px;
    }
  }
}

.dashboardButton[sel=true] {
  border-top: 4px solid transparent;
  border-bottom: 4px solid $lanthorn-orange;
}

.profileButton {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  @include up-to-sm {
    width: 35px;
  }
}

.logout {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 35px;
  cursor: pointer;
  @include up-to-sm { width: 25px; }
}

.rightItemsImg {
  width: 36px;
}
