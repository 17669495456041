@import 'assets/stylesheets/colors.scss';

.card {
  background-color: white;
  padding: 50px 50px 30px 50px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.text1 {
  font-size: 22px;
  letter-spacing: -0.85px;
  color: $text-black;
  padding: 25px;
  text-align: center;
}

.text2 {
  font-size: 18px;
  letter-spacing: -0.64px;
  color: $text-grey;
  padding-bottom: 30px;
  text-align: center;
}

.step0 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stepsButtonContainer {
  display: flex;
  width: 600px;
  justify-content: space-between;
}

.loader {
  margin: 200px;
}
