@import 'assets/stylesheets/colors.scss';

.live {
  height: 186px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  * { box-sizing: border-box; }
}

.liveChart {
  width: 186px;
  height: 100%;
  margin: 0px 0px 0px 20px;
}

.controls {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  .control {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 5px 5px 5px 10px;
    background: none;
    border: none;

    .color {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      margin: 0px 10px 0px 0px;
      border: solid 1px;
    }

    .label {
      font-size: 14px;
      letter-spacing: 0.39px;
      text-align: left;
    }
  }
}
