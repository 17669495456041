@import 'assets/stylesheets/colors.scss';

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 53px;
  width: 100%;
  background-color: white;
  border-radius: 8.9px;
}

.segment {
  height: 53px;
  background: transparent;
  border: none;
  width: 100%;
  border-radius: 8.9px;
  font-size: 19px;
  padding: 0px 5px;
  letter-spacing: 0.53px;
}

.selected {
  @extend .segment;
  background-color: $very-light-pink;
  font-weight: 600;
  letter-spacing: 0.53px;
  color: $rusty-orange;
}

.unselected {
  @extend .segment;
  color: $twilight-blue;
}

.separator {
  width: 2px;
  height: 37px;
  opacity: 1;
  border-radius: 0.5px;
  background-color: $line-grey;
}

.invisibleSeparator {
  display: none;
}
