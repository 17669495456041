@import 'assets/stylesheets/colors.scss';
@import 'assets/stylesheets/mixins.scss';
@import '../common.module.scss';
@import './occupancyRuleCard/occupancyRuleCard.module.scss';

.title {
  @include up-to-xs {
    margin-bottom: 19px;
  }
}

.description {
  color: $bluey-grey;
  margin: 3px 0 0 19px;
  font-size: 17px;
}

.bottomDiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 30%;
}

.formContainer {
  width: 100%;
}

.areasContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 15px;
  margin-top: 21px;
}

.addAreaButton {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 40px;
  @include up-to-xs {
    justify-content: flex-start;
  }
}

.areaListContainer {
  width: 100%;
  min-height: 90px;
  bottom: 112px;
  border-radius: 8px;
  border: solid 1px $light-periwinkle;
  border-style: dashed;
  margin-top: 10px;
  @include up-to-xs {
    margin-bottom: 0px;
  }
}

.areaList {
  @extend .subtitle;
  margin-top: 17px;
  margin-left: 24px;
}

.areaListButtonContainer {
  margin: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.maxCapacityInput {
  width: 68px;
}

.inputContainer {
  width: auto;
  margin-top: 5px;
}

.occupantsDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.addCamerasSubtitle {
  @extend .subtitle;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.toggleDiv {
  display: flex;
  align-items: center;
  margin-top: 34px;
}

.checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 6px;
}

.alertsContainer {
  margin-left: 20px;
  padding-left: 20px;
  border-left: solid 1px lightgrey;
}

.notificationsContainer {
  margin: 20px 0px 0px 0px;
  max-width: 970px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  @include up-to-xs {
    flex-direction: column;
    align-items: flex-start;
  }
}

.notificationsSubtitle {
  @extend .subsubtitle;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-right: 20px;
}

.alertCapacitySubtitle {
  @extend .notificationsSubtitle;
  margin-top: 20px;
  height: 50px;
}

.slackInputDiv {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  @include up-to-xs {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
}

.authorizeSlackButton {
  width: 242px;
  height: 50px;
  padding: 0px 10px 0px 10px;
  border-style: none;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 2.03px;
  color: $twilight-blue;
  background-color: $white-three;
  border-radius: 0 5px 5px 0;
  @include up-to-xs {
    width: 100%;
    border-radius: 0 0 5px 5px;
  }
}

.slackInput {
  border-radius: 5px 0px 0px 5px;
  @include up-to-xs {
    border-radius: 5px 5px 0 0;
  }
}

.loader {
  margin: 40px 50px;
  transform: scale(0.5);
}


// Non business rules

.occupancyRulesContainer {
  margin-left: 10px;
}

.occupancyRulesCallout {
  margin-top: 10px;
}

.ruleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 920px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
  @include up-to-xs {
    max-width: 260px;
  }
}

.addRuleButton {
  height: 30px;
  width: 30px;
  background-color: $lanthorn-orange-light;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 25px;
}

.addRuleContainer {
  @extend .occupancyRuleContainer;
  align-items: center;
}
