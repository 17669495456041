@import 'assets/stylesheets/colors.scss';

.content {
  margin: 30px 0px 30px 0px;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

.loader {
  margin: 70px;
  transform: scale(0.8);
}
