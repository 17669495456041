@import 'assets/stylesheets/colors.scss';
@import 'assets/stylesheets/mixins.scss';
@import '../common.module.scss';

.description {
  color: $bluey-grey;
  font-size: 14px;
  font-weight: normal;
  margin: 13px 0px 24px 10px;
}

.loginCredentials {
  @extend .subtitle;
  margin: 36px 5px 37px 10px;
}

.username {
  @extend .subtitle;
  margin-bottom: 14px;
}

.password {
  @extend .subtitle;
  margin-bottom: 14px;
}

.formContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.toggleDiv {
  display: flex;
  align-items: center;
}

.credentialsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 15px;
}
.passwordDiv {
  margin-bottom: 27px;
}
.checkCapabilitiesDiv {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 17px;
}
.capabilitiesDiv {
  margin-left: 10px;
  margin-top: 8px;
  p {
    margin-bottom: 6px;
  }
}
