@import 'assets/stylesheets/colors.scss';

.background {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    margin-bottom: 15px;
  }
}

.loader {
  transform: scale(0.8);
  margin: 70px;
}
