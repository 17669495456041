@import 'assets/stylesheets/colors.scss';
@import 'assets/stylesheets/mixins.scss';

.buttonDiv {
  border-radius: 23px;
  border: solid 2px $pale-grey-two;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 14px 7px 14px 7px;
  @include up-to-xs {
    font-size: 16px;
    height: 40px;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 18px 13px 0px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  color: $twilight-blue;
}

.icon {
  margin-right: 8px;
  margin-left: 18px;
  width: 25px;
  height: 25px;
}
