@import 'assets/stylesheets/colors.scss';

.cameraArea {

  button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 12px;
    color: $twilight-blue;
    border: none;
    background-color: transparent;
    padding: 0px 0px 5px 19px;
    margin-bottom: 15px;
    cursor: pointer;
    img {
      margin-right: 9px;
      width: 18px;
    }
  }
}
