/*
  Add colors as variables here like this:

  $color-name: #000000;

  Please refrain from adding code that generates CSS.
*/

$lanthorn-orange: #ff7f29;
$lanthorn-orange-light: #ffa96f;
$lanthorn-grey: #414042;
$twilight-blue: #0e2f5b;
$occupancy-red: #a73636;
$bluey-grey: #8c9cb4;
$dark-indigo: #080e2a;
$aqua-marine: #30efab;
$purpley: #8549e2;
$lanthorn-dark-blue: #014259;
$enabled-slack: #00e676;
$button-skyblue: #5881ff;

$light-grey: #e4e9f2;
$light-grey-2: #e4e4e4;
$error-text-red: #ff4f4f;
$old-error-text-red: #ff0033;
$delete-button-red: #e13737;
$button-grey: #d4d4d4;
$disabled-button-grey: #cfcfcf;
$line-grey: #a3a3a7;
$tab-button-selected: #fe414d;
$success-text-green: #77dd77;
$warning-text-yellow: #FFD24E;
$textfield-placeholder-grey: #949494;
$textfield-background-white: #fdfefe;
$halloween-black: #121212;
$notifications-background-grey: #eeeeee;
$text-black: #0e0f10;
$text-grey: #545355;
$divider-grey: #54535560;
$datepicker-border-grey:  #dae1e3;
$processor-reboot-grey: lightgrey;

$main-background-grey: #f6f6f6;
$graph-grey: #c1c1c1;
$background-grey-85: #e4e9f2d9;
$background-grey-45: #e4e9f273;
$background-edit: #cacaca;
$background-loading-dark: rgba(0, 0, 0, 0.9);
$background-sub-bar: #F2F4F8;
$editCameraButtonBackground: #E3E9F3;
$background-camera-settings-header: #e6ebf3;

$rusty-orange: #d95d0b;
$very-light-pink: #fff6f0;
$bluey-grey: #8c9cb4;
$pale-grey: #f7f9fd;
$twilight-blue: #0e2f5b;
$white-two: #e6e6e6;
$mango: #ff7f29;
$pale-grey-two: #e9ebef;
$light-periwinkle: #cddafd;
$very-light-pink-two: #fef6f0;
$green-brown: #4a3307;
$white-three: #f0f0f0;
$pale-orange: #ffa562;
$white-four: #f2f2f2;
$bluegrey: #899cb6;

$occupancy-rules-red: #f56a5d;
$occupancy-rules-yellow: #fcd981;
$occupancy-rules-orange: #f0a254;

// Dashboard

$live-footer: #f4f6fc;
$historic-footer: #eff0fb;
$social-distancing-infringements: #b6c0cf;
$occupancy-threshold-background: #f3f8ff;

//   Metrics
//      Facemask
$facemask-green: #2bad5b;
$facemask-red: #ff5a62;
$facemask-grey: #c1c1c1;
//      InOut
$in-orange: $lanthorn-orange;
$out-blue: $button-skyblue;
$balance-grey: #919191;
//      Social Distancing
$social-distancing-safe: $facemask-green;
$social-distancing-critical: #862d2d;
$social-distancing-high: #ff485c;
$social-distancing-low: #ffa5a5;

// Indicators
$indicator-text-grey: #515151;
$indicator-border-grey: #C3C3C3;
$indicator-border-red: #ffeff3;
$indicator-text-red: #f3055a;
$indicator-border-green: #eafbf2;
$indicator-text-green: #05b26a;

:export {
  lanthornOrange: $lanthorn-orange;
  darkIndigo: $dark-indigo;
  aquaMarine: $aqua-marine;
  twilightBlue: $twilight-blue;
  occupancyRed: $occupancy-red;
  purpley: $purpley;
  graphGrey: $graph-grey;
  lanthorn-dark-blue: $lanthorn-dark-blue;
  successTextGreen: $success-text-green;
  errorTextRed: $error-text-red;
  warningTextYellow: $warning-text-yellow;
  lanthornOrangeLight: $lanthorn-orange-light;
  halloweenBlack: $halloween-black;

  facemaskGreen: $facemask-green;
  facemaskRed: $facemask-red;
  facemaskGrey: $facemask-grey;

  inOrange: $in-orange;
  outBlue: $out-blue;
  balanceGrey: $balance-grey;

  socialDistancingSafe: $social-distancing-safe;
  socialDistancingCritical: $social-distancing-critical;
  socialDistancingHigh: $social-distancing-high;
  socialDistancingLow: $social-distancing-low;

  occupancyRulesRed: $occupancy-rules-red;
  occupancyRulesYellow: $occupancy-rules-yellow;
  occupancyRulesOrange: $occupancy-rules-orange;
}
