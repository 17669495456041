@import "breakpoints";

@mixin up-to-xs() {
  @media (max-width: map-get($breakpoints, "xs")) {
    @content;
  }
}

@mixin up-to-sm() {
  @media (max-width: map-get($breakpoints, "sm")) {
    @content;
  }
}

@mixin up-to-md() {
  @media (max-width: map-get($breakpoints, "md")) {
    @content;
  }
}

@mixin between-xs-sm() {
  @media (min-width:  map-get($breakpoints, "xs")+1) and (max-width: map-get($breakpoints, "sm")) {
    @content;
  }
}

@mixin between-sm-md() {
  @media (min-width:  map-get($breakpoints, "sm")+1) and (max-width: map-get($breakpoints, "md")) {
    @content;
  }
}

@mixin between-md-lg() {
  @media (min-width:  map-get($breakpoints, "md")+1) and (max-width: map-get($breakpoints, "lg")) {
    @content;
  }
}

@mixin between-lg-xl() {
  @media (min-width:  map-get($breakpoints, "lg")+1) and (max-width: map-get($breakpoints, "xl")) {
    @content;
  }
}

@mixin beyond-xl {
  @media (min-width: map-get($breakpoints, "xl")+1) {
    @content;
  }
}
