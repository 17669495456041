@import 'assets/stylesheets/colors.scss';

.container {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
}

.container[vertical-label=true] {
  flex-direction: column;
}

.phantomCheckbox {
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

.checkboxContainer {
  height: 14px;
  width: 14px;
  border-radius: 2px;
  background-color: white;
  border: solid 1px $light-grey;
}

.checkboxContainer[dark=true] {
  background-color: $textfield-placeholder-grey;
}

.checkboxContainer[on=true] {
  border-color: $lanthorn-orange;
  background-color: $lanthorn-orange-light;
  background-image: url(../../assets/images/checkmark.svg);
  background-repeat: no-repeat;
  background-size: 80% 80%;
  background-position: center;
}

.label {
  color: $halloween-black;
}
