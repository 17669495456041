@import 'assets/stylesheets/colors.scss';
@import 'assets/stylesheets/mixins.scss';

.background {
  border-radius: 8px;
  background-color: white;
  min-width: 550px;
  max-width: calc(100vw - 20px);
  max-height: calc(100vh - 20px);
  box-sizing: border-box;
  overflow-y: auto;
  position: relative;
  @include up-to-xs {
    min-width: 0px;
    max-width: calc(100vw - 10px);
    max-height: calc(100vh - 10px);
  }
}

.dismiss {
  position: absolute;
  top: 15px;
  right: 15px;
  border: none;
  background: none;
  cursor: pointer;
  img {
    height: 20px;
    width: 20px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  margin: 58px 50px 0px 50px;
  @include up-to-xs { margin: 40px 25px 0px 25px; }
  .bottomMargin {
    height: 50px;
    @include up-to-xs { height: 40px; }
  }
}
