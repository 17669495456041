@import 'assets/stylesheets/colors.scss';

.card {
  height: 329px;
  width: 300px;
  flex-grow: 1;
  border-radius: 5px;
  box-shadow: 0 20px 47px -27px rgba(50, 52, 54, 0.3);
  background-color: white;
  margin: 15px 15px 30px 0px;
  box-sizing: border-box;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.title {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.67px;
  color: $dark-indigo;
}
.cameraImage {
  width: 250px;
  border-radius: 5px;
  margin: 0px 17px 0px 17px;
}
.leftChevron {
  border: none;
  background-color: transparent;
  transform: rotate(-180deg);
  cursor: pointer;
}
.rightChevron {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.text {
  font-size: 16px;
  letter-spacing: 0.44px;
  color: $dark-indigo;
}
.footer {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.loader {
  transform: scale(0.8);
}
