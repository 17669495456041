@import 'assets/stylesheets/colors.scss';

.numbers {
  width: 314px;
  padding: 8px 10px 5px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: $live-footer;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: $dark-indigo;

  .smallNumber {
    font-size: 20px;
    letter-spacing: 0.56px;
  }

  .bigNumber {
    font-size: 24px;
    letter-spacing: 0.67px;
    font-weight: 600;
  }

  .metricIndicator {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background: none;
    border: none;
    font-size: 16px;
    letter-spacing: 0.44px;
    font-weight: 500;
    color: $dark-indigo;
    div {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      margin: 0px 8px 0px 0px;
      border: solid 1px;
    }
  }

  .detections {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    span { @extend .bigNumber }
  }

  .infringements {
    margin: 10px 0px;
    padding: 2px 14px 4px 11px;
    border-radius: 5px;
    border: solid 1px $social-distancing-infringements;
    background-color: white;
    width: 292px;
    .row {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      span { @extend .smallNumber }
      .indicator { @extend .metricIndicator }
    }

    .title {
      @extend .row;
      font-size: 18px;
      letter-spacing: 0.5px;
      span { @extend .bigNumber }
    }
  }

  .safe {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    span { @extend .smallNumber }
    .indicator { @extend .metricIndicator }
  }
}
.liveData {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.featuredMetric {
  background-color: $live-footer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: $dark-indigo;
  width: 314px;
  padding: 8px 10px 5px 12px;
  border-radius: 5px;
}
.featuredMetricNumber {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 292px;
  align-self: center;
  font-size: 60px;
  font-weight: 600;
  letter-spacing: 2.11px;
  color: $twilight-blue;
  padding: 8px;
  margin: 10px;
  border-radius: 10px;
  border: solid 1px $social-distancing-infringements;
}
