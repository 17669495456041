@import 'assets/stylesheets/colors.scss';
@import 'assets/stylesheets/mixins.scss';

.background {
  width: 100%;
  height: 100vh;
  @include up-to-xs {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  display: grid;
  grid-template-columns: 296px 1fr;
  background-color: $pale-grey;
}

.sidebar {
  @include up-to-xs {
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 31px 0px 44px 0px;
    border-radius: 10px;
    box-shadow: 0 0 18px -10px $bluey-grey;
    width: 100%;
  }
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 44px;
  padding-right: 42px;
}

.logoImage {
  @include up-to-xs {
    position: absolute;
    visibility: hidden;
    width: 0;
    height: 0;
  }
  margin-top: 47.5px;
  margin-bottom: 32.5px;
  width: 219px;
  height: 45px;
}

.sidebarButton {
  margin-top: 42.5px;
  @include up-to-xs {
    margin-top: 0;
  }
}

.content {
  padding-left: 90px;
  padding-right: 90px;
  background-color: $pale-grey;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  @include up-to-xs {
    padding-left: 10px;
    padding-right: 10px;
  }

  .phantomDiv {
    width: 100%;
    height: 0;
    @include up-to-xs {
      margin-top: 110px;
    }
  }

}

.sectionTitle {
  font-size: 34px;
  font-weight: 300;
  color: $dark-indigo;
  width: 100%;
  text-indent: 20px;
}

.header {
  width: 100%;
  padding-top: 15px;
  padding-bottom: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.headerLogoImage {
  width: 166px;
  height: 34px;
}
