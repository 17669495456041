@import 'assets/stylesheets/colors.scss';
@import 'assets/stylesheets/mixins.scss';
@import '../common.module.scss';

.title {
  @include up-to-xs {
    margin-bottom: 19px;
  }
}

.subtitle {
  margin-top: 27px;
}

.formContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.callout {
  padding: 16px 0px 14px 0px;
}

.checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 6px;
}
