@import 'assets/stylesheets/colors.scss';
@import 'assets/stylesheets/mixins.scss';

.button {
  height: 44px;
  border-style: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  color: $rusty-orange;
  background-color: $very-light-pink;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @include up-to-xs {
    font-size: 15px;
    height: 35px;
    padding-right: 11px;
  }
}

.button[status=disabled] {
  color: $bluey-grey;
  background-color: transparent;
  @include up-to-xs {
    font-size: 0;
  }
}

.button[status=completed] {
  color: $twilight-blue;
  background-color: transparent;
  @include up-to-xs {
    font-size: 0;
  }
}

.button:disabled {
  color: $bluey-grey;
  background-color: transparent;
}


.icon {
  margin-left: 11px;
  margin-right: 14px;
  @include up-to-xs {
    margin-right: 7px;
  }
}
