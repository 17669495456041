@import 'assets/stylesheets/colors.scss';
@import 'assets/stylesheets/fonts.scss';

.card {
  height: 500px;
  min-width: 800px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 20px 47px -27px rgba(50, 52, 54, 0.3);
  background-color: white;
  margin: 15px 0px 30px 0px;
  box-sizing: border-box;
  flex-direction: column;
  display: flex;

  * {
    box-sizing: border-box;
  }
}

.main {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  .left {
    padding: 25px 0px 0px 0px;
    width: 380px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .loading {
      top: 43%;
      transform: scale(0.7);
    }
  }

  .right {
    flex-grow: 1;
    height: 100%;
    padding: 60px 15px 40px 10px;

    .loading {
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transform: scale(0.7);
    }
  }
}

.title {
  width: 100%;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.67px;
  text-align: left;
  text-indent: 38px;
  color: $dark-indigo;
  display: flex;
  align-content: center;
}
.tooltipContainer {
  position: relative;
  display: inline-block;
  img {
    margin-left: 8px;
    width: 26px;
    height: 26px;
    position: absolute;
    left: 0;
  }
  span {
    visibility: hidden;
    background-color: black;
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 6px;
    position: absolute;
    width: 330px;
    z-index: 1;
    font-size: 14px;
    text-indent: 0px;
  }
}
.tooltipContainer:hover span {
  visibility: visible;
}

.live {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.indicator {
  font-size: 21px;
  font-weight: 600;
  letter-spacing: 1.31px;
  height: 36px;
  padding: 0px 15px 0px 15px;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $twilight-blue;
}

.footerSection {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.footer {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;


  .liveFooter {
    @extend .footerSection;
    background-color: $live-footer;
    width: 380px;
    .lastUpdated {
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 0.28px;
      margin: 0px 0px 0px 10px;
      color: $bluey-grey;
      div {
        transform: scale(0.1);
      }
    }
  }

  .historicFooter {
    @extend .footerSection;
    background-color: $historic-footer;
    flex-grow: 1;
  }
}

.titleSection {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  h1 {
    margin: 0px 0px 15px 0px;
    font-size: 37px;
    font-weight: normal;
    letter-spacing: normal;
    color: $twilight-blue;
  }
}
