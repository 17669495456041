@import 'assets/stylesheets/colors.scss';
@import 'assets/stylesheets/mixins.scss';
@import '../../common.module.scss';

.occupancyRuleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 200px;
  width: 440px;
  margin: 10px;
  border: solid 1px $light-periwinkle;
  border-style: dashed;
  border-radius: 8px;
  padding: 15px 25px;
  flex-grow: 0;
  box-sizing: border-box;
  position: relative;
  @include up-to-xs {
    width: 240px;
    height: 330px;
    padding: 15px 15px;
  }
}

.occupancy {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0px 0px 10px 0px;
}

.timerange {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.hours {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0px 0px 10px 0px;
  width: 100%;
  @include up-to-xs {
    flex-direction: column;
    align-items: flex-start;
  }
}

.hour {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @include up-to-xs {
    align-items: flex-start;
    flex-direction: column;
    margin: 5px 0px 5px 0px;
  }
}

.hourFields {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.days {
  @extend .hours;
  justify-content: flex-start;
  margin: 0px 0px 10px 0px;
  @include up-to-xs {
    margin: 0px 0px 15px 0px;
  }
}

.daysFields {
  @extend .hourFields;
}

.checkboxContainer {
  margin-right: 15px;
}

.deleteButton {
  height: 30px;
  width: 30px;
  padding: 0;
  margin: 0;
  background-color: $delete-button-red;
  border-radius: 6px;
  border: solid 1px $delete-button-red;
  display: flex;
  justify-content: center;
  align-items: center;
}

.warningSign {
  position: absolute;
  bottom: 10px;
  right: 25px;
  height: 80px;
  width: 80px;
  @include up-to-xs {
    bottom: 10px;
    right: 12px;
    height: 45px;
    width: 45px;
  }
}
