@import 'assets/stylesheets/colors.scss';
@import 'assets/stylesheets/fonts.scss';
@import 'assets/stylesheets/mixins.scss';

.cardTitle {
  @extend .title;
  margin-top: 51px;
  margin-bottom: 30px;
  @include up-to-xs {
    font-size: 31px;
    margin-top: 20px;
  }
}

.subTitle {
  @extend .secondaryText;
  margin-top: 30px;
  margin-bottom: 30px;
  @include up-to-xs {
    font-size: 18px;
  }
}

.form {
  width: 100%;
}

.errorMessage {
  margin-top: 8px;
  font-size: 20px;
  font-weight: 200;
  color: $error-text-red;
  @include up-to-xs {
    font-size: 16px;
  }
}

.button {
  margin: 78px 0px 88px 0px;
  @include up-to-xs {
    margin: 54px 0px 36px 0px;
  }
}
