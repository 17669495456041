@import 'assets/stylesheets/colors.scss';
@import 'assets/stylesheets/mixins.scss';

.text {
  font-size: 27px;
  letter-spacing: -0.95px;
  text-align: center;
  @include up-to-xs {
    font-size: 24px;
  }
}

.buttonBox {
  margin-top: 20px;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @include up-to-xs {
    width: 100%;
    flex-direction: column;
  }
  
  button {
    margin: 0px 14px 0px 14px;
    @include up-to-xs {
      margin: 5px 0px 5px 0px;
      width: 100%;
    }
  }
}

.errorText {
  color: $error-text-red;
  font-weight: 200;
  margin-top: 11px;
}
