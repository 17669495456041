@import 'assets/stylesheets/colors.scss';

.title {
  margin-top: 51px;
  font-weight: 300;
  font-size: 44px;
  color: $halloween-black;
}

.subTitle {
  color: $halloween-black;
  margin-top: 46px;
  font-size: 20px;
  font-weight: 300;
}
.form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 488px;
}

.submitButton {
  margin-top: 30px;
  margin-bottom: 88px;
  width: 100%;
  height: 70px;
  border-style: none;
  border-radius: 8px;
  font-size: 22px;
  font-weight: 500;
  background-color: $aqua-marine;
  cursor: pointer;
}

.errorMessage {
  margin-top: 30px;
  font-size: 20px;
  font-weight: 200;
  color: $error-text-red;
}

.scoreContainer {
  margin-top: 8px;
  text-align: end;
  font-size: 19px;
  width: 100%;
}
