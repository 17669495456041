.container {
    display: flex;
    justify-content: center;
    align-items: center;

  canvas {
    background-color: transparent;
    position: absolute;
  }

  .innerContainer {
    position: relative;

    .cursor {
      z-index: 40;
      height: 30px;
      position: absolute;
    }

    .demoBackground {
      width: 100%;
      height: 100%;
    }
  }
}
