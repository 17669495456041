@import 'assets/stylesheets/colors.scss';
@import 'assets/stylesheets/mixins.scss';
@import 'assets/stylesheets/variables.scss';

.background {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logoImage {
  margin-top: 8%;
  margin-bottom: 7%;
  max-width: 90%;
  height: $nav-bar-height;
  color: white;
}

.loginBox {
  max-width: 95vw;
  width: 595px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 50px;
  padding: 0px 53px 0px 53px;
  @include up-to-xs {
    padding: 0px 15px 0px 15px;
  }
  box-sizing: border-box;
}
