@import 'assets/stylesheets/colors.scss';
@import 'common/textInput/textInput.module.scss';

.select {
  @extend .textInput;
  color: $halloween-black;
  text-indent: 0px;
  border-color: $light-grey-2;
  background: url(../../assets/images/chevronDownBlue.svg) no-repeat right 25px center transparent;
  appearance: none;
  background-color: $textfield-background-white;
}

.select[fat=true] {
  @extend .textInput[fat=true];
}

.select[valid-value=false] {
  color: $bluey-grey;
}
