@import 'assets/stylesheets/colors.scss';
@import 'assets/stylesheets/variables.scss';

.content {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.sidebar {
  width: 350px;
  min-height: calc(100vh - #{$nav-bar-height});
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 39px 20px 20px 20px;
  box-sizing: border-box;

  h2 {
    margin-bottom: 10px;
    font-size: 25px;
    font-weight: 400;
  }

  .segmentedControl {
    margin-bottom: 20px;
  }

  .buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    button {
      border: none;
      padding: 5px;
      margin: 0px 5px;
      border-radius: 8.9px;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0.53px;
      background-color: transparent;
      cursor: pointer;
    }
  }
  .buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    .button {
      padding: 5px;
      margin: 0px 5px;
      border-radius: 4px;
      width: 100%;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0.53px;
      cursor: pointer;
    }
    .allButton {
      @extend .button;
      border: solid 1px $pale-orange;
      background-color: $pale-orange;
    }
    .clearButton {
      @extend .button;
      border: solid 1px $bluey-grey;
      background-color: transparent;
      color: $bluey-grey;
    }
  }
}
.exportDataButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $twilight-blue;
  border: none;
  border-radius: 26px;
  width: 220px;
  height: 52px;
  font-size: 17px;
  font-weight: 500;
  color: white;
  cursor: pointer;
  position: fixed;
  bottom: 15px;
  left: 35px;
  img {
    margin-right: 8px;
  }
}

.metrics {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 15px 15px 15px 15px;
  overflow: hidden;
  background-color: $pale-grey;
}

.timePicker {
  margin: 0px 0px 15px 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-width: 830px;
  width: 100%;
}

.timeSegmentedControlContainer {
  width: 600px;
}
.errorMessage {
  font-size: 20px;
  font-weight: 200;
  color: $error-text-red;
}

.noIds {
  width: 100%;
  padding: 50px 40px 15px 40px;
  overflow: hidden;
  background-color: $pale-grey;
  font-size: 25px;
  font-weight: 400;
  color: $lanthorn-dark-blue;
}
