@import 'assets/stylesheets/colors.scss';
@import 'assets/stylesheets/mixins.scss';

.container {
  width: 65px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.changeButton {
  width: 16px;
  height: 16px;
  border: solid 1.3px $light-grey;
  border-radius: 5px;
  background-color: white;
  background-image: url(../../assets/images/chevronDown.svg);
  background-repeat: no-repeat;
  background-size: 11px;
  background-position-x: center;
  background-position-y: center;
}

.controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  .buttonIncrease {
    @extend .changeButton;
    transform: rotate(180deg);
  }
  .buttonDecrease {
    @extend .changeButton;
  }
}


.textInput {
  width: calc(100% - 18px);
  height: 32px;
  border: solid 1.3px $light-grey;
  border-radius: 5px;
  background-color: white;
  text-indent: 16px;
  font-size: 16px;
  color: $twilight-blue;
  box-sizing: border-box;
}

.textInput::-webkit-outer-spin-button,
.textInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.textInput[type=number] {
  -moz-appearance: textfield;
}

.textInput:focus {
  outline: none;
  border: solid 1px $lanthorn-orange-light;
}

.textInput::placeholder {
  font-size: 1em;
  color: $bluey-grey;
}

.textInput[disabled] {
  background-color: $button-grey;
  border: solid 1px $disabled-button-grey;
  color: $lanthorn-grey;
}

.textInputError {
  @extend .textInput;
  outline: none;
  border: solid 1px $error-text-red;
  color: $error-text-red;
}

.errorMessage {
  margin: 6px 0px 0px 17px;
  font-size: 14px;
  color: $error-text-red;
}
