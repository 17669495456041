.container {
  canvas {
    background-color: transparent;
    position: absolute;
  }

  .background {
    background-color: transparent;
    position: absolute;
  }

  .buttonBar {
    background-color: transparent;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    button {
      height: 100%;
      border-radius: 0px 0px 10px 10px;
      height: 35px;
      font-size: 20px;
    }
  }
}
