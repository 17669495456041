@import 'assets/stylesheets/colors.scss';
@import 'assets/stylesheets/variables.scss';

.container {
  height: calc(100vh - #{$nav-bar-height});
  background-color: $main-background-grey;
}

body {
  background-color: $main-background-grey;
}
