@import 'assets/stylesheets/colors.scss';

.card {
  background-color: white;
  padding: 50px 50px 30px 50px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.heatmapButton {
  margin-top: 10px;
}
.title {
  margin: 0px 0px 15px 0px;
  font-size: 37px;
  font-weight: normal;
  letter-spacing: normal;
}
.liveVideoButton {
  margin-top: 20px;
}
