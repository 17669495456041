@import 'assets/stylesheets/colors.scss';

.heatmapContainer {
  width: 540px;
  height: 405px;
  margin-bottom: 70px;
  padding-left: 10px;
  position: relative;
}

.title {
  font-size: 36px;
  color: $dark-indigo;
  text-align: center;
  margin-bottom: 45px;
  margin-top: 30px;
  font-weight: normal;
}

.dateContainer {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-left: 40px;
  padding-right: 40px;

  .dateText {
    font-size: 17px;
    color: $dark-indigo;
    text-align: center;
    margin-right: 20px;
  }

  .dateButton {
    font-size: 18px;
    letter-spacing: -0.64px;
    height: 53px;
  }
}

.toggle {
  background-color: $pale-grey;
}

.toggleDiv {
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding-left: 40px;
}

.toggleText {
  font-size: 18px;
  margin-right: 5px;
  padding-bottom: 5px;
}

.modal {
  width: 500px;
  background: white;
  border: 1px solid #ccc;
  box-shadow: -2rem 2rem 2rem rgba(black, 0.2);
  filter: blur(0);
  transform: scale(1);
  opacity: 1;
  border-radius: 5px;

  h2 {
    border-bottom: 1px solid #ccc;
    padding: 1rem;
    margin: 0;
    color: $dark-indigo;
  }

  .content {
    margin-top: 20px;
    padding: 1rem;
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;

    .dateInput {
      height: 40px;
      width: 150px;
      cursor: pointer;
      border-radius: 5px;
      text-align: center;
      padding-right: 5px;
    }

    .dateLabel {
      margin-top: 15px;
      color: $dark-indigo;
      font-size: 18px;
    }
  }
}

.backgroundImage {
  position: absolute;
  top: 0;
  width: 98%;
  height: 100%;
}
.canvasBackgroundImage {
  width: 100%;
  height: 100%;
}
