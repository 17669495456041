@import 'assets/stylesheets/variables';
@import 'assets/stylesheets/colors';

/*
  Add your font-face definitions on this file. Please
  define the font-family as a variable on the variables file.
  For example:

  @font-face {
    font-family: $primary-font-family;
    src: ...
  }

  If you don't need any custom fonts or are using a CDN then
  leave this file empty.
*/

.title {
  font-family: $primary-font-family;
  font-size: 44px;
  font-weight: 300;
  color: $halloween-black;
  text-align: center;
}

.secondaryText {
  font-family: $primary-font-family;
  font-size: 19px;
  font-weight: 300;
  color: $halloween-black;
  text-align: center;
}

.dashboardButton {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
}
