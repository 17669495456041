@import 'assets/stylesheets/colors.scss';

.footer {
  display: flex;
  justify-content: flex-end;
  background-color: white;
  align-items: center;
  height: 25px;
}
.link {
  color: $bluey-grey;
  text-decoration: none;
  margin-right: 10px;
}
