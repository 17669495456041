.container {
  @include up-to-xs {
    margin-top: 26px;
  }
  margin-top: 90px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 1;
}

.title {
  @include up-to-xs {
    font-size: 24px;
    margin-bottom: 8px;
    margin-left: 10px;
  }
  color: $twilight-blue;
  font-size: 37px;
  font-weight: normal;
  margin-bottom: 33px;
}

.subtitle {
  @include up-to-xs {
    font-size: 17px;
    margin-bottom: 8px;
  }
  color: $twilight-blue;
  font-size: 19px;
  font-weight: 600;
  margin-left: 10px;
  margin-top: 44px;
}

.subsubtitle {
  @include up-to-xs {
    font-size: 15px;
    margin-bottom: 8px;
  }
  color: $twilight-blue;
  font-size: 17px;
  font-weight: 600;
  margin-left: 10px;
}

.stepButtonsDiv {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    flex-grow: 1;
  
    .stepButton {
      height: 56px;
      width: 146px;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 2.03px;
      @include up-to-xs {
        letter-spacing: 1.8px;
        font-size: 16px;
        width: 120px;
        height: 40px;
      }
    }
  }
