@import 'assets/stylesheets/colors.scss';

.container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  max-width: 488px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 68px 0px 68px 0px;
}

.sectionTitle {
  font-size: 34px;
  font-weight: 300;
  color: $dark-indigo;
  text-align: center;
}

.profileForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.slackContainer {
  width: 494px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 8px 0 41px -7px rgba(107, 108, 116, 0.1);
  padding: 24px 32px 24px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  background-color: $textfield-background-white;
  font-size: 20px;
  color: $dark-indigo;
  font-weight: 500;
}

.button {
  margin-left: 20px;
  font-size: 16px;
  width: 147px;
  height: 42px;
  border-radius: 4px;
  background-color: $enabled-slack !important;
  color: white !important;
}

.buttonDisabled {
  width: 150px;
  margin-left: 20px;
  font-size: 18px;
  background-color: $background-grey-85 !important;
  border: 1px solid $background-grey-45 !important;
}

.input {
  margin-top: 40px;
}

.channelText {
  font-size: 15px;
  font-weight: 500;
  margin-top: 25px;
  text-align: center;
  margin-bottom: 30px;
  color: $dark-indigo;
}

.saveSlackButton {
  height: 42px;
}

.integrationError {
  color: $error-text-red;
  text-align: center;
  margin-bottom: 20px;
  font-size: 22px;
}
