@import 'assets/stylesheets/colors.scss';

.card {
  height: 329px;
  width: 300px;
  flex-grow: 1;
  border-radius: 5px;
  box-shadow: 0 20px 47px -27px rgba(50, 52, 54, 0.3);
  background-color: white;
  margin: 15px 15px 30px 0px;
  box-sizing: border-box;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.title {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.67px;
  color: $dark-indigo;
}
.box {
  width: 249px;
  height: 175px;
  margin: 0px 17px 0px 17px;
  border-radius: 5px;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cameraCount {
  font-size: 76px;
  font-weight: 600;
  letter-spacing: 2.11px;
  color: $twilight-blue;
}
.text {
  font-size: 16px;
  letter-spacing: 0.44px;
  color: $dark-indigo;
}
